import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  Poster,
} from "../components/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "about/poster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 488, maxHeight: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const About = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <Grid container alignItems="center" style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">About</PageHeader>
            <Typography>
            Sewing Pictures is an Adelaide-based film production company which aims to produce authentic, honest, personal projects which reflect contemporary Australia.
            </Typography>
            <Typography>
            Producer Khoa Do has worked in film and television since 2001 and has had extensive experience in both film, television and the arts.            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default About
